import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Modal from "../components/ProductsModal/ProductsModal"
import Layout from "../components/SubLayout"
import { navItemsSr } from "../constants/navigation"

import { Container, ProductWrapper } from "../styled/products"

class Proizvodi extends React.Component {
  render() {
    return (
      <Layout
        data={this.props.data}
        location={this.props.location}
        navItems={navItemsSr}
      >
        <Helmet
          title="Proizvodi - Miia"
          meta={[
            { name: "description", content: "Sampleee" },
            { name: "keywords", content: "sampleee, something" },
          ]}
        />
        <Container>
          <ProductWrapper>
            <Modal
              data={this.props.data}
              title={"Vidi detalje"}
              subTitles={["Održavanje", "Dimenzije", "Težina"]}
            />
          </ProductWrapper>
        </Container>
      </Layout>
    )
  }
}

export default Proizvodi

export const proizvodiQuery = graphql`
  query ProizvodiQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    mainlogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
    us: allContentfulProduct(filter: { node_locale: { eq: "sr" } }) {
      edges {
        node {
          title
          id
          contentful_id
          maintenance
          size
          weight
          image {
            title
            description
            url
          }
        }
      }
    }
  }
`
