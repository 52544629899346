import styled from "styled-components"

export const ProductWrapper = styled.div`
  display: grid;
  grid-row-gap: 50px;
  grid-column-gap: 250px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1102px) {
    grid-row-gap: 40px;
    grid-column-gap: 150px;
  }
  @media (max-width: 999px) {
    grid-row-gap: 0;
    grid-column-gap: 0;
    grid-template-columns: 1fr;
  }
`

export const Container = styled.div`
  display: grid;
  place-items: center;
`
